// Filter
const commonFilter = [
    {colMd: "3", label: "Class", type: "multi-select", name: "class_id"},
    {colMd: "3", label: "Status", type: "select", name: "active_status_id"},
];
const studentFilter = [
    {colMd: "3", label: "School", type: "multi-select", name: "school_id"},
    {colMd: "3", label: "Branch", type: "multi-select", name: "branch_id"},
    ...commonFilter
];

const studentFilterPrin = [
    ...commonFilter
];

const studentFilterTeach = [
    ...commonFilter
];
const analyticFilter = [
    {title: "Skills",
        fields: [
            {label: "Unique Competencies", type: "multi-select", name: "skill_id", sub_select: true, sub_select_key: "skill_oper"},
            {label: "Career Skills", type: "multi-select", name: "career_skill_id", sub_select: true, sub_select_key: "career_skill_oper"},
            {label: "Personal Skills", type: "multi-select", name: "personal_skill_id", sub_select: true, sub_select_key: "personal_skill_oper"},
        ]
    },
    {title: "Education and Diplomas",
        fields: [
            {label: "Classification", type: "multi-select", name: "edu_classify_id", sub_select: true, sub_select_key: "edu_classify_oper"},
            {label: "Subject/Course/Stream", type: "multi-select", name: "edu_subject_id", sub_select: true, sub_select_key: "edu_subject_oper"},
            {label: "Any Achievement", type: "multi-select", name: "edu_rank_id"},
        ]
    },
    {title: "Work Experience, Volunteering, Internships...",//Clubs, Organisations, Training and Passion/Personal Projects
        fields: [
            {label: "Classification", type: "multi-select", name: "exp_classify_id", sub_select: true, sub_select_key: "exp_classify_oper"},
            {label: "Individual/Team", type: "multi-select", name: "exp_work_type_id"},
            {label: "Institution/Firm Type", type: "multi-select", name: "exp_company_type_id", sub_select: true, sub_select_key: "exp_company_type_oper"},
        ]
    },
    {title: "Projects, Assignments, Papers, Essays, Researches...",//Stories, Poems, Videos, Podcasts
        fields: [
            {label: "Classification", type: "multi-select", name: "research_classify_id", sub_select: true, sub_select_key: "research_classify_oper"},
            {label: "Individual/Team", type: "multi-select", name: "research_work_type_id"},
            {label: "Subject/Domain", type: "multi-select", name: "research_subject_id", sub_select: true, sub_select_key: "research_subject_oper"},
            {label: "Zone/Area", type: "multi-select", name: "research_com_level_id", sub_select: true, sub_select_key: "research_com_level_oper"},
            // {label: "Any Achievement", type: "multi-select", name: "skill_id", sub_select: true, sub_select_key: "skill_oper"},
        ]
    },
    {title: "",
        fields: [
            {label: "Any Achievement", type: "multi-select", name: "research_rank_id"},
        ]
    },
    {title: "Sports, Games, Art, Design, Tech, Science, Humanities...",
        fields: [
            {label: "Classification", type: "multi-select", name: "sport_classify_id", sub_select: true, sub_select_key: "sport_classify_oper"},
            {label: "Individual/Team", type: "multi-select", name: "sport_work_type_id"},
            {label: "Field/Category/Domain", type: "multi-select", name: "sport_domain_id", sub_select: true, sub_select_key: "sport_domain_oper"},
            {label: "Zone/Area", type: "multi-select", name: "sport_level_id", sub_select: true, sub_select_key: "sport_level_oper"},
            // {label: "Any Achievement", type: "multi-select", name: "skill_id", sub_select: true, sub_select_key: "skill_oper"},
        ]
    },
    {title: "",
        fields: [
            {label: "Any Achievement", type: "multi-select", name: "sport_rank_id"},
        ]
    },
    {title: "Leadership, Honours and Awards",
        fields: [
            {label: "Classification", type: "multi-select", name: "award_classify_id", sub_select: true, sub_select_key: "award_classify_oper"},
            {label: "Field/Domain", type: "multi-select", name: "award_domain_id", sub_select: true, sub_select_key: "award_domain_oper"},
            {label: "Zone/Area", type: "multi-select", name: "award_level_id", sub_select: true, sub_select_key: "award_level_oper"},
            {label: "Any Achievement", type: "multi-select", name: "award_rank_id"},
        ]
    },
];
//Filter
const filterInitial= {
    // id: "",
    // school_id: "",
    // class_id: "",
    // name: "",
    // email: "",
    // mobile_number: "",
    // country_id: "",
    // state_id: "",
    // city_id: "",
    // member_type: "",
    // completion_id: "",
    // validity_id: "",
    // competition_id: "",
    // port_pending_id: "",
    // port_filled_id: "",
    // active_status_id: "",
    // created_at: "",
    // mobile_code_id: {id: 101, phone_code: "91"},
    skill_oper: {id: 'OR', oper: "OR"},
    career_skill_oper: {id: 'OR', oper: "OR"},
    personal_skill_oper: {id: 'OR', oper: "OR"},
    edu_classify_oper: {id: 'OR', oper: "OR"},
    edu_subject_oper: {id: 'OR', oper: "OR"},
    exp_classify_oper: {id: 'OR', oper: "OR"},
    exp_company_type_oper: {id: 'OR', oper: "OR"},
    research_classify_oper: {id: 'OR', oper: "OR"},
    research_subject_oper: {id: 'OR', oper: "OR"},
    research_com_level_oper: {id: 'OR', oper: "OR"},
    sport_classify_oper: {id: 'OR', oper: "OR"},
    sport_domain_oper: {id: 'OR', oper: "OR"},
    sport_level_oper: {id: 'OR', oper: "OR"},
    award_classify_oper: {id: 'OR', oper: "OR"},
    award_domain_oper: {id: 'OR', oper: "OR"},
    award_level_oper: {id: 'OR', oper: "OR"},
}
// const filterInitialTeach= {
//     id: "",
//     class_id: "",
//     name: "",
//     email: "",
//     mobile_number: "",
//     member_type: "",
//     completion_id: "",
//     competition_id: "",
//     active_status_id: "",
//     mobile_code_id: {id: 101, phone_code: "91"}
// }
const filterFieldType = {
    //   id: "string",
      school_id: "array",
      class_id: "array",
      branch_id: "array",
    //   name: "string",
    //   email: "string",
    //   mobile_number: "string",
    //   country_id: "array",
    //   state_id: "array",
    //   city_id: "array",
    //   member_type: "object",
    //   completion_id: "object",
    //   validity_id: "object",
    //   competition_id: "object",
    //   port_pending_id: "array",
    //   port_filled_id: "array",
      active_status_id: "object",
    //   created_at: "string",
    //   mobile_code_id: "object",
    //   port_pending_oper: "object",
    //   port_filled_oper: "object"
    skill_oper: "object",
    career_skill_oper: "object",
    personal_skill_oper: "object",
    edu_classify_oper: "object",
    edu_subject_oper: "object",
    exp_classify_oper: "object",
    exp_company_type_oper: "object",
    research_classify_oper: "object",
    research_subject_oper: "object",
    research_com_level_oper: "object",
    sport_classify_oper: "object",
    sport_domain_oper: "object",
    sport_level_oper: "object",
    award_classify_oper: "object",
    award_domain_oper: "object",
    award_level_oper: "object",
    skill_id: "array",
    career_skill_id: "array",
    personal_skill_id: "array",
    edu_classify_id: "array",
    edu_subject_id: "array",
    edu_rank_id: "array",
    exp_classify_id: "array",
    exp_work_type_id: "array",
    exp_company_type_id: "array",
    research_classify_id: "array",
    research_work_type_id: "array",
    research_subject_id: "array",
    research_com_level_id: "array",
    research_rank_id: "array",
    sport_classify_id: "array",
    sport_work_type_id: "array",
    sport_domain_id: "array",
    sport_level_id: "array",
    sport_rank_id: "array",
    award_classify_id: "array",
    award_domain_id: "array",
    award_level_id: "array",
    award_rank_id: "array",
    keyword: "string"
};
const fieldOperator = [
    "skill_id",
    "career_skill_id",
    "personal_skill_id",
    "edu_classify_id",
    "edu_subject_id",
    "exp_classify_id",
    "exp_company_type_id",
    "research_classify_id",
    "research_subject_id",
    "research_com_level_id",
    "sport_classify_id",
    "sport_domain_id",
    "sport_level_id",
    "award_classify_id",
    "award_domain_id",
    "award_level_id",
];
const fieldOperatorKey = {
    "skill_id": "skill_oper",
    "career_skill_id": "career_skill_oper",
    "personal_skill_id": "personal_skill_oper",
    "edu_classify_id": "edu_classify_oper",
    "edu_subject_id": "edu_subject_oper",
    "exp_classify_id": "exp_classify_oper",
    "exp_company_type_id": "exp_company_type_oper",
    "research_classify_id": "research_classify_oper",
    "research_subject_id": "research_subject_oper",
    "research_com_level_id": "research_com_level_oper",
    "sport_classify_id": "sport_classify_oper",
    "sport_domain_id": "sport_domain_oper",
    "sport_level_id": "sport_level_oper",
    "award_classify_id": "award_classify_oper",
    "award_domain_id": "award_domain_oper",
    "award_level_id": "award_level_oper",
};
const fieldUnfilter = [
    "skill_oper",
    "career_skill_oper",
    "personal_skill_oper",
    "edu_classify_oper",
    "edu_subject_oper",
    "exp_classify_oper",
    "exp_company_type_oper",
    "research_classify_oper",
    "research_subject_oper",
    "research_com_level_oper",
    "sport_classify_oper",
    "sport_domain_oper",
    "sport_level_oper",
    "award_classify_oper",
    "award_domain_oper",
    "award_level_oper",
];

const data = {
    studentFilter,
    studentFilterPrin,
    studentFilterTeach,
    filterInitial,
    analyticFilter,
    filterFieldType,
    fieldOperator,
    fieldOperatorKey,
    fieldUnfilter
    // filterInitialTeach
};

export default data;