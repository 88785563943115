import React from 'react';
// import Col from 'react-bootstrap/Col';
// import Form from 'react-bootstrap/Form';
import Select  from 'react-select';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {
//   Col,
  Form,
//   InputGroup
} from 'react-bootstrap';
// import Accordion from 'react-bootstrap/Accordion';
import { RSstandardStyle } from '../../../config/react-select';
// import { FormUpload } from '../../form/upload';
import { currentMonthYear, todayDateForm } from '../../form/date';
import './../../../assets/css/pages/modal.css';
// import { LoaderMedium } from '../components/loader';

function FilterInput(props) {
    return (
      <> 
        {/* {props.form.fields.map((field, field_index) => (
            <Col xs={12} md={props.field.colMd} key={`field_${field_index}`} className="p-1"> */}
                <Form.Group className="mb-0">
                    <Form.Label
                        // htmlFor={props.field.name}
                    >
                        {props.field.label}
                        {props.field.tagline && <span className="fw-light">:&nbsp;{props.field.tagline}</span>}
                    </Form.Label>
                    {/* Input */}
                    {(props.field.type === "number" 
                        || props.field.type === "text" 
                        || props.field.type === "email"
                    ) 
                    ?
                        <>
                        <div className="d-flex">
                        {props.field.name === "mobile_number" && (
                            <div className="w-200p">
                            <Select
                                id={'mobile_code_id'}
                                options={props.selectOptions['mobile_code_id']?.data} 
                                // defaultValue={props.data['mobile_code_id']}
                                defaultValue={{id: 101, phone_code: "91"}}
                                value={props.data['mobile_code_id']||""}
                                placeholder={props.field.placeholderData && props.field.placeholderData.length > 0 ? `${props.field.placeholderData[0]}`: ''}
                                onChange={(value) => props._updateInput(props.formType, 'object', 'select-react', 'mobile_code_id', value)}
                                getOptionLabel ={(option) => `+${option[props.selectOptions['mobile_code_id']?.label]}`}
                                getOptionValue ={(option) => option[props.selectOptions['mobile_code_id']?.value]}
                                menuPosition={'fixed'}
                                styles={RSstandardStyle}
                            />
                        </div>
                        )}
                        <input
                            className="form-control"
                            id={props.field.name}
                            name={props.field.name}
                            // type="text"
                            // type={props.field.type === "number" ? "text" : props.field.type} 
                            type={props.field.type}
                            value={props.data[props.field.name] || ''}
                            onChange={(e) => props._updateInput(props.formType, 'object', 'input', props.field.name, e.target.value)}
                            // placeholder={typeof props.field.placeholderData !== "undefined" && props.field.placeholderData.length > 0  ? `Eg.${props.field.placeholderData.length === 1 ? '' : ' 1'}: ${props.field.placeholderData[0]}` : props.field.placeholder}
                            readOnly={props.isProcess}
                            min={props.field.type === "number" ? 0 : ''} 
                        />
                        </div>
                        </>
                    :
                        // Textarea
                        props.field.type === "textarea"
                        ?
                            <>
                            <textarea
                                className="form-control"
                                id={props.field.name}
                                name={props.field.name}
                                rows={props.field.rows}
                                value={props.data[props.field.name] || ''}
                                onChange={(e) => props._updateInput(props.formType, 'object', 'textarea', props.field.name, e.target.value)}
                                // placeholder={typeof props.field.placeholderData !== "undefined" && props.field.placeholderData.length > 0  ? `Eg.${props.field.placeholderData.length === 1 ? '' : ' 1'}: ${props.field.placeholderData[0]}` : props.field.placeholder}
                                placeholder={typeof props.field.placeholderData !== "undefined" && props.field.placeholderData.length > 0  ?  props.field.placeholderData[0] : ""}
                                readOnly={props.isProcess}
                            />
                            </>
                        :   
                        
                    // Select-Single-Start/End Year
                    props.field.type === "year"
                    ? 
                        // <div className="d-flex">
                            <div className="w-140p">
                                <Select
                                    isClearable={true}
                                    id={props.field.name}
                                    options={props.yearData} 
                                    defaultValue={props.data[props.field.name]}
                                    value={props.data[props.field.name] || ""}
                                    // label={"Select Year"}
                                    placeholder={props.field.placeholderData && props.field.placeholderData.length > 0 ? `Eg. ${props.field.placeholderData[0]}`: ''}
                                    onChange={(value) => props._updateInput(props.formType, 'object', 'select-react', props.field.name, value)}
                                    getOptionLabel ={(option) => option.year}
                                    getOptionValue ={(option) => option.id}
                                    menuPlacement="auto"
                                    menuPosition={'fixed'}
                                    styles={RSstandardStyle}
                                />
                            </div>
                        :
                                props.field.type === "month"
                                    ?
                                    <div>
                                        <input type="month" className="form-control w-150p p-1" name={props.field.name} value={props.data[props.field.name] || ""} onChange={(e) => props._updateInput(props.formType, 'object','input', props.field.name, e.target.value)} max={currentMonthYear()} min="1922-01"/>
                                        
                                    </div>
                                    :
                                        props.field.type === "multi-select"
                                        ?
                                        <div className="d-flex">
                                        {(typeof props.field.sub_select !== "undefined" && props.field.sub_select) && (
                                            <div className="sub-select">
                                            <Select
                                                options={props.selectOptions[props.field.sub_select_key]?.data} 
                                                defaultValue={props.data[props.field.sub_select_key]}
                                                // defaultValue={{id: 101, phone_code: "91"}}
                                                value={props.data[props.field.sub_select_key]||""}
                                                // placeholder={props.field.placeholderData && props.field.placeholderData.length > 0 ? `${props.field.placeholderData[0]}`: ''}
                                                placeholder=""
                                                onChange={(value) => props._updateInput(props.formType, 'object', 'select-react', props.field.sub_select_key, value)}
                                                getOptionLabel ={(option) => option[props.selectOptions[props.field.sub_select_key]?.label]}
                                                getOptionValue ={(option) => option[props.selectOptions[props.field.sub_select_key]?.value]}
                                                menuPosition={'fixed'}
                                                styles={RSstandardStyle}
                                            />
                                        </div>
                                        )}
                                        <div className={`${(typeof props.field.sub_select !== "undefined" && props.field.sub_select) ? 'sub-parent' : 'w-100'}`}>
                                            <Select
                                                closeMenuOnSelect={false}
                                                id={props.field.name}
                                                options={props.selectOptions[props.field.name]?.data} 
                                                defaultValue={props.data[props.field.name]}
                                                value={props.data[props.field.name] || ""}
                                                placeholder={props.field.placeholderData && props.field.placeholderData.length > 0 ? `${props.field.placeholderData[0]}`: ''}
                                                // placeholder={props.field.placeholder ? props.field.placeholder: ''}
                                                onChange={(value) => props._updateInput(props.formType, 'object', 'select-multi', props.field.name, value)}
                                                getOptionLabel ={(option) => option[props.selectOptions[props.field.name]?.label]}
                                                getOptionValue ={(option) => option[props.selectOptions[props.field.name]?.value]}
                                                // menuPlacement="auto"
                                                menuPosition={'fixed'}
                                                styles={RSstandardStyle}
                                                classNamePrefix="reactCSel"
                                                // isOptionDisabled={() => (props.data[props.field.name] && props.data[props.field.name].length) >= props.selectOptions[props.field.name].max}
                                                isSearchable={true}
                                                // filterOption={(option, searchtext) => customFilter(option, searchtext, props.selectOptions[props.field.name].format)}
                                                // components={{ValueContainer: (e) => selectComponent(e, props.selectOptions[props.field.name].format.key)}}
                                                // selectionLimit={2}
                                                isMulti
                                            />
                                        </div>
                                        </div>
                                        :
                                            props.field.type === "select"
                                            ?
                                            <div className="w-100">
                                                <Select
                                                    // closeMenuOnSelect={false}
                                                    isClearable={true}
                                                    id={props.field.name}
                                                    options={props.selectOptions[props.field.name]?.data} 
                                                    defaultValue={props.data[props.field.name]}
                                                    value={props.data[props.field.name] || ""}
                                                    // placeholder={props.field.placeholderData && props.field.placeholderData.length > 1 ? `Eg. 1: ${props.field.placeholderData[0]}`: props.field.placeholderData.length > 0
                                                    // ? `${props.field.placeholderData[0]}` : ''}
                                                    placeholder={props.field.placeholderData && props.field.placeholderData.length > 0 ? `${props.field.placeholderData[0]}`: ''}
                                                    onChange={(value) => props._updateInput(props.formType, 'object', 'select-react', props.field.name, value)}
                                                    getOptionLabel ={(option) => option[props.selectOptions[props.field.name]?.label]}
                                                    getOptionValue ={(option) => option[props.selectOptions[props.field.name]?.value]}
                                                    // menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                    styles={RSstandardStyle}
                                                    // isDisabled ={true}
                                                    // formatOptionLabel={(e) => formatOptionLabel(e, props.selectOptions[props.field.name].format)}
                                                    // classNamePrefix="reactCSel"
                                                    // isOptionDisabled={() => (props.data[props.field.name] && props.data[props.field.name].length) >= props.selectOptions[props.field.name].max}
                                                    // isSearchable={true}
                                                    // filterOption={(option, searchtext) => customFilter(option, searchtext, props.selectOptions[props.field.name].format)}
                                                    // components={{ValueContainer: (e) => selectComponent(e, props.selectOptions[props.field.name].format.key)}}
                                                    // isOptionDisabled={() => props.data[props.field.name]?.length > props.selectOptions[props.field.name]?.max}
                                                    // selectionLimit={2}
                                                    // isMulti
                                                />
                                            </div>
                                            :
                                                props.field.type === "date"
                                                ?
                                                <input
                                                    className="form-control"
                                                    id={props.field.name}
                                                    name={props.field.name}
                                                    // type="text"
                                                    type={"date"}
                                                    min={props.field.date_type && props.field.date_type === "future" ?  `${todayDateForm()}` : '1920-01-01'}
                                                    max={props.field.date_type && props.field.date_type === "past" ?  `${todayDateForm()}` : '2124-12-31'}
                                                    value={props.data[props.field.name] || ""}
                                                    onChange={(e) => props._updateInput(props.formType, 'object', 'input', props.field.name, e.target.value)}
                                                    placeholder={typeof props.field.placeholderData !== "undefined" && props.field.placeholderData.length > 0  ? `Eg.${props.field.placeholderData.length === 1 ? '' : ' 1'}: ${props.field.placeholderData[0]}` : props.field.placeholder}
                                                    readOnly={props.isProcess}
                                                />
                                                :
                                                <></>
                    }
                    {(props.field.placeholderData && props.field.placeholderData.length > 1)
                        ?
                        <div className="fs-12p fst-italic mt-1 text-black-50">
                            {props.field.placeholderData.map((p_data, p_index) => (
                                <span key={`place_hold${p_index}`}>
                                {p_index !== 0 && (
                                    <span>
                                        <b>Eg. {p_index+1}</b>: {p_data} {p_index+1 !== props.field.placeholderData.length && (', ')}
                                    </span>
                                )}
                                </span>
                            ))}
                        </div>
                        : (props.field.placeholderData && props.field.placeholderData.length === 1
                            && (props.field.type === "year_to_from" 
                                || props.field.type === "month_year_from"
                                || props.field.type === "month"))
                            ?
                            <div className="fs-12p fst-italic mt-1 text-black-50">
                                <span><b>Eg.</b>: {props.field.placeholderData[0]}</span>
                            </div>
                            : <></>
                    }
                </Form.Group>
            {/* </Col>
        ))} */}
      </>
    );
}
  
export default FilterInput;