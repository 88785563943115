import React from "react";

function ExpiryDays(props){
    return (
        <>
            <span className={`${props.days === 0 
                                ? 'text-danger'
                                : props.days < 16 
                                    ? 'text-warning'
                                    : ''}`}>
                {props.days > 0
                    ? <span>{props.days} {props.days > 1 ? 'Days' : 'Day'}</span>
                    : 'Expired'
                }
            </span>
        </>
    )
}

export {ExpiryDays};