import React from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {
  Col,
  Form,
  InputGroup
} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import './../../../assets/css/pages/modal.css';
import FilterInput from './../filter/filter-input';
// import { LoaderMedium } from '../components/loader';

function FilterFloatMaster(props) {
    // console.log(props.data, 'props.data')
    // const [expandedItem, setExpandedItem] = useState("0")
    // useEffect(() => {
    //     setTimeout(() => {
    //         setExpandedItem("1");
    //         console.log('llll')
    //     }, 3000);
    //     }, []);
    return (
      <> 
            {/* <div>
                {props.loader 
                ?
                    <div className="text-center mt-5 mb-5">
                        <LoaderMedium size={45} />
                    </div>
                :
                    <> */}
                    <Accordion
                        className="filter-custom filter-float"
                        // defaultActiveKey={expandedItem}
                        // onSelect={(e) => {
                        //     console.log(e)
                        //     if (e !== null){
                        //         setExpandedItem(e);
                        //     }
                        // }}
                    >
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                Filter & Download
                            </Accordion.Header>
                            <Accordion.Body className="p-2">
                                <Form className="filter-form">
                                    <fieldset>
                                    {props._formData.map((form, form_index) => (
                                        <div key={`form_${form_index}`}>
                                            {/* <legend>{form.label}</legend> */}
                                            <div className="row m-0">
                                                {form.display_type === 'col'
                                                ?
                                                form.fields.map((field, field_index) => (
                                                    <Col xs={12} md={field.colMd} key={`field_${field_index}`} className="p-1">
                                                        <FilterInput
                                                            {...props}
                                                            // form={form}
                                                            field={field}
                                                        />
                                                    </Col>
                                                ))
                                                :
                                                    form.display_type === 'table'
                                                    ?
                                                        <div className="table-responsive p-0">
                                                        <table className="table table-bordered filter-table">
                                                            <tbody>
                                                                {form.fields.map((field, field_index) => (
                                                                    <tr key={`field_${field_index}`}>
                                                                        <th>{field.title}</th>
                                                                        {field.fields.map((td_field, td_index) => (
                                                                            <td key={`td_${td_index}`}>
                                                                            <FilterInput
                                                                                {...props}
                                                                                // form={form}
                                                                                field={td_field}
                                                                            />
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        </div>
                                                    :
                                                    <></>
                                            }
                                            </div>
                                        </div>
                                    ))}
                                    </fieldset>
                                    <p className="text-end mb-0">
                                        <button className="btn-inverse-light btn-link reset-btn" onClick={(e) => props._resetFilterForm(e)}>Reset Filter</button>
                                    </p>
                                    <div className="text-center mb-2 mt-3 justify-content-center filter-excel">
                                        <div className="d-inline-flex">
                                        <div>
                                        {!props._isProcess[props.formType]
                                        ?
                                            // props._isBtnDisabled[props.formType]
                                            // ?
                                            // :
                                                <button className="btn btn-primary bg-color-blue btn-sm" type="submit" onClick={(e) => props._submitForm(e, props.formType)}><span className="fs-6">Search</span></button>
                                        :
                                            <button className="btn btn-primary bg-color-blue btn-sm d-flex" disabled type="submit"><img className="loader-filter" src={require('../../../assets/images/Image-Loader.gif')} alt="Loader" /> &nbsp;<span className="fs-6">Searching...</span></button>
                                        }
                                        </div>
                                        {props._is_download && (
                                            <>
                                            <div>
                                            {!props._isProcess.download
                                            ?
                                                <button className="btn btn-primary bg-color-blue btn-sm ms-2 " type="submit" onClick={(e) => props._dataExport(e, 'download')}><p className="fs-6 mb-0">Export to Excel</p></button>
                                            :
                                                <button className="btn btn-primary bg-color-blue btn-sm ms-2 d-flex" disabled type="submit"><img className="loader-filter" src={require('../../../assets/images/Image-Loader.gif')} alt="Loader" /> &nbsp;<p className="fs-6 mb-0">Exporting...</p></button>
                                            }
                                            </div>
                                            <InputGroup className="ms-2">
                                                {/* <InputGroup.Text><i className="fa fa-envelope"></i>&nbsp;<em>*</em></InputGroup.Text> */}
                                                <FloatingLabel
                                                    controlId="floatingDown"
                                                    label="Excel Sheet Name"
                                                    className="w-200p"
                                                >
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Excel Sheet Name" 
                                                        autoComplete="user_name" 
                                                        value={props._excelSheetName}
                                                        onChange={(e) => props._updateInput('download', 'object', 'input', '', e.target.value)}
                                                    />
                                                </FloatingLabel>
                                            </InputGroup>
                                            </>
                                        )}
                                        </div>
                                    </div>
                                </Form>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    {/* </>
                }
            </div> */}
      </>
    );
}
  
export default FilterFloatMaster;